import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl, getPathUrl } from 'utils/path'
import { getClientAndDoctorUrl, isDoctorUrl, normalizeUrl } from 'utils/url'
import {
  ArticleNewsPageData,
  NewsArticlesDetailPage
} from '@root/src/components/org.news-article-detail-page/news-articles-detail-page.component'
import { parseToDate } from '@root/src/utils/date-parser/date-parser'
import { Query } from '@root/src/data/graphql/graphql-types'
import { graphql } from 'gatsby'
import React from 'react'

export interface NewsDetailTemplateProps {
  slug: string
  last4News: {
    title: string
    slug: string
    description: string
    publishedDate: string
    id: string
  }[]
}

class NewsDetailTemplate extends React.Component<PageProps<NewsDetailTemplateProps, Query>> {
  render() {
    const data = this.props.data.cosmicjsNoticias
    const isDoctorPath = isDoctorUrl(this.props.location.pathname)
    const currentPath = `${getPathUrl(appPaths.news.path, isDoctorPath)}/${
      this.props.pageContext.slug
    }${this.props.location.search}`
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const currentPageUrl = normalizeUrl(`${siteUrl}${currentPath}`)

    const lastNews = this.props.pageContext.last4News.filter(item => item.id !== data.id)
    const [clientPath, doctorPath] = getClientAndDoctorUrl(this.props.location)
    const pageData: ArticleNewsPageData = {
      title: data.title,
      description: data.metadata.descricaoSimples,
      publishedDate: parseToDate(data.metadata.noticiaData),
      htmlContent: data.content,
      previousContentData: lastNews,
      tags: (data.metadata.tags || []).map(tag => tag.title).sort(),
      seo: {
        title: data.metadata.seo.titulo,
        imageUrl: data.metadata.seo.imagem.url,
        imageAlt: data.metadata.seo.imagemAlternativa,
        description: data.metadata.seo.descricao,
        article: {
          published_time: parseToDate(data.metadata.noticiaData),
          modified_time: parseToDate(data.modified_at),
          autor: ['Fleury, medicina e saúde'],
          section: 'Noticias',
          tag: (data.metadata.tags || []).map(tag => tag.title)
        }
      }
    }
    return (
      <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
        <IndexLayout location={this.props.location}>
          <NewsArticlesDetailPage
            data={pageData}
            slug={this.props.pageContext.slug}
            currentPageUrl={currentPageUrl}
            isDoctor={isDoctorUrl(this.props.location.pathname)}
            canonicalUrl={getCanonicalUrl(siteUrl, appPaths.news.path, this.props.pageContext.slug)}
            staticInfo={{
              col2Title: this.props.data.cosmicjsInformacoesEstaticas.metadata.col2Titulo,
              col2ButtonTitle: this.props.data.cosmicjsInformacoesEstaticas.metadata.col2BotaoTexto,
              col2ButtonTo: getPathUrl(appPaths.news.path, isDoctorPath),
              col2ReadMoreButton: this.props.data.cosmicjsInformacoesEstaticas.metadata
                .botaoLerDetalheTexto,
              section: 'Noticias'
            }}
          />
        </IndexLayout>
      </ClientDoctorSelectContext.Provider>
    )
  }
}

export default NewsDetailTemplate

export const query = graphql`
  query NewsDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsNoticias(slug: { eq: $slug }) {
      id
      title
      content
      published_at
      modified_at
      metadata {
        descricaoSimples
        noticiaData
        tags {
          title
        }
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "noticias" }) {
      metadata {
        col2Titulo
        col2BotaoTexto
        botaoLerDetalheTexto
      }
    }
  }
`
