import styled from 'styled-components'
import { H3 } from 'atomic'

export const PreviousContentTitle = styled(H3)``

export const PreviousContentWrapper = styled.a`
  :hover {
    ${PreviousContentTitle} {
      text-decoration: underline;
    }
  }
`
