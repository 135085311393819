import { Color, FontSize, FontWeight, Spacing } from 'atomic/legacy/obj.constants'
import ColorFunc from 'color'
import styled from 'styled-components'
import { BadgeProps } from './badge.component'

export const BadgeStyled = styled.div<BadgeProps>`
  padding: ${Spacing.Small} ${Spacing.Medium};
  background-color: ${(props) =>
    props.kind === 'primary'
      ? ColorFunc(props.theme.color.primary)
          .alpha(0.1)
          .hsl()
          .string()
      : Color.GrayLight};
  border-radius: ${0.5 * (parseInt(FontSize.Small, 10) + 2 * parseInt(Spacing.Small, 10))}px;
  align-self: flex-start;
  justify-content: center;

  :hover {
    background: ${Color.GrayXLight};
  }
`

export const BadgeTextStyled = styled.span<{ kind }>`
  color: ${(props) => (props.kind === 'primary' ? props.theme.color.primary : Color.GrayXDark)};
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Lighter};
`
