import { Border, HeaderHeight, Spacing, BodyStyle } from 'atomic'
import styled from 'styled-components'
import { ReactStyledFlexboxgrid } from 'react-styled-flexboxgrid'

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100%;
`

export interface MultipleCollumnWrapperProps {
  numberOfCollumnsMobile?: number
  numberOfCollumns: number
  // ex: '12px'
  collumnGap?: string
}

export const MultipleCollumnWrapper = styled.div<MultipleCollumnWrapperProps>`
  column-count: ${props => (props.numberOfCollumnsMobile ? `${props.numberOfCollumnsMobile}` : 1)};
  @media all and (min-width: 64em) {
    column-count: ${props => `${props.numberOfCollumns}`};
    column-gap: ${props => (props.collumnGap ? `${props.collumnGap}` : '')};
  }
`

export const BorderRoundWrapper = styled.div`
  overflow: hidden;
  border-radius: ${Border.Radius};
  position: relative;
`

export const StickButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  margin: auto;
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
  width: 90%;
  @media all and (min-width: 48em) {
    width: 30%;
  }
`

export const TopStickyWrapper = styled.div<ReactStyledFlexboxgrid.IColProps>`
  position: sticky;
  top: ${HeaderHeight.Mobile}px;
  @media all and (min-width: 64em) {
    top: ${HeaderHeight.Desk}px;
  }
  overflow: auto;
  z-index: 2;
  max-height: calc(100vh - ${HeaderHeight.Desk}px);
  padding-bottom: ${Spacing.XLarge};
`

export const InvisibleWrapper = styled.div`
  opacity: 0;
`

export const OverflowXHiddenWrapper = styled.div`
  overflow-x: hidden;
`

export const FieldsWrapper = styled.div`
  margin-right: ${Spacing.Medium};
`
export const FieldsWrapperNew = styled.div``

export const BottomLinkWrapper = styled.div`
  margin-top: auto;
`

export const ExternalHtmlWrapper = styled.div`
  ${BodyStyle};
`
