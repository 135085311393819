import { BodySecondary, Color, FaIcon, Hbox } from 'atomic'
import React from 'react'
interface ShareIconsProps {
  title: string
  text: string
  url: string
}
export const ShareIconsNewsCell: React.FunctionComponent<ShareIconsProps> = props => (
  <Hbox>
    <Hbox.Item wrap>
      <BodySecondary>Compartilhar:</BodySecondary>
    </Hbox.Item>
    <Hbox.Separator />
    <Hbox.Item hAlign="center" wrap>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}`}
        role="button"
        aria-label="facebook share"
      >
        <FaIcon.Facebook size="lg" color={Color.GrayXDark} />
      </a>
    </Hbox.Item>
    <Hbox.Separator />
    <Hbox.Item hAlign="center" wrap>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          props.url
        )}&title=${encodeURIComponent(props.title)}&summary=${encodeURIComponent(
          props.text
        )}&source=${encodeURIComponent(`Fleury, medicina e saúde`)}`}
        role="button"
        aria-label="linkedin share"
      >
        <FaIcon.Linkedin size="lg" color={Color.GrayXDark} />
      </a>
    </Hbox.Item>
    <Hbox.Separator />
    <Hbox.Item hAlign="center" wrap>
      <a
        href={`${encodeURI(
          `https://twitter.com/intent/tweet?text=${props.text}`
        )}&url=${encodeURIComponent(props.url)}`}
        role="button"
        aria-label="twitter share"
      >
        <FaIcon.Twitter size="lg" color={Color.GrayXDark} />
      </a>
    </Hbox.Item>
    <Hbox.Separator />
    <Hbox.Item hAlign="center" wrap>
      <a
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${props.text} ${props.url}`
        )}`}
        role="button"
        aria-label="whatsapp share"
      >
        <FaIcon.Whatsapp size="lg" color={Color.GrayXDark} />
      </a>
    </Hbox.Item>
  </Hbox>
)
